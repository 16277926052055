import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { getDataSources } from "@/components/forecast.service";
import Loader from "@/components/Loader";
import { EventBus } from "@/lib/event-bus";
import { Card } from "@/pages/index/Card";
import { DataSource } from "@/types";

export const DataStateCard = () => {
	const [loading, setLoading] = useState(false);

	const [dataSources, setDataSources] = useState<DataSource[]>([]);

	const fetchData = async () => {
		console.log("Get Data Sources");
		setLoading(true);
		const dataList = await getDataSources();
		if (dataList) {
			for (const data of dataList) {
				data.date = dayjs(data.date).format("DD.MM.YYYY");
			}
			setDataSources(dataList);
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchData().then(() => {
			EventBus.$on("update-data-source", fetchData);
		});
	}, []);

	return (
		<Card header={"Data State"}>
			{loading ? (
				<Loader />
			) : (
				<table className="date-table">
					<tbody>
						{dataSources?.map((item, i) => (
							<tr key={i}>
								<th>{item.name}</th>
								<td>{item.date}</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
			<br />
			<p>
				This section show the <strong>actual date</strong> of the data in database. In opposition to
				the Forecast Parameters section, where dates reflects data state in the day of forecast.
			</p>
		</Card>
	);
};
