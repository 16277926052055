//https://dev.to/mihaiandrei97/jwt-authentication-using-axios-interceptors-55be
import axios, { RawAxiosRequestHeaders } from "axios";

let failedQueue = [];
const isRefreshing = false;

const processQueue = (error) => {
	failedQueue.forEach((prom) => {
		if (error) {
			// @ts-ignore
			prom.reject(error);
		} else {
			// @ts-ignore
			prom.resolve();
		}
	});

	failedQueue = [];
};

export function createAxiosClient({ options, getCurrentAccessToken, logout, setAccessToken }) {
	const client = axios.create(options);

	client.interceptors.request.use(
		(config) => {
			if (config.authorization !== false) {
				const token = getCurrentAccessToken();
				if (token) {
					// @ts-ignore
					config.headers.Authorization = "Bearer " + token;
				}
			}
			return config;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	client.interceptors.response.use(
		(response) => {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		(error) => {
			const originalRequest = error.config;
			// In "axios": "^1.1.3" there is an issue with headers, and this is the workaround.
			// https://github.com/axios/axios/issues/5089
			originalRequest.headers = JSON.parse(
				JSON.stringify(originalRequest.headers || {})
			) as RawAxiosRequestHeaders;

			// If error, process all the requests in the queue and logout the user.
			const handleError = (error) => {
				processQueue(error);
				logout();
				return Promise.reject(error);
			};

			// Refresh token conditions
			// if (
			// 	refreshToken &&
			// 	error.response?.status === 401 &&
			// 	error.response.data.detail === "Given token not valid for any token type" &&
			// 	originalRequest?.url !== refreshTokenUrl &&
			// 	originalRequest?._retry !== true
			// ) {
			// 	if (isRefreshing) {
			// 		return new Promise(function (resolve, reject) {
			// 			// @ts-ignore
			// 			failedQueue.push({ resolve, reject });
			// 		})
			// 			.then(() => {
			// 				return client(originalRequest);
			// 			})
			// 			.catch((err) => {
			// 				return Promise.reject(err);
			// 			});
			// 	}
			// 	isRefreshing = true;
			// 	originalRequest._retry = true;
			// 	return client
			// 		.post(refreshTokenUrl, {
			// 			refresh: refreshToken,
			// 		})
			// 		.then((res) => {
			// 			console.log("refresh token", res.data);
			// 			const tokens = {
			// 				access: res.data?.access_token,
			// 				refresh: res.data?.refresh,
			// 			};
			// 			setRefreshedTokens(tokens);
			// 			processQueue(null);
			//
			// 			return client(originalRequest);
			// 		}, handleError)
			// 		.finally(() => {
			// 			isRefreshing = false;
			// 		});
			// }

			// Refresh token missing or expired => logout user...
			if (
				(error.response?.status === 401 && error.response?.data?.detail === "Unauthorized") ||
				(error.response?.status === 403 && error.response?.data?.detail === "Forbidden")
			) {
				return handleError(error);
			}

			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
			return Promise.reject(error);
		}
	);

	return client;
}
