import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";

import App from "@/App";
import AuthService from "@/components/auth/service";
import ForgotPassword from "@/pages/auth/ForgotPassword";
import Register from "@/pages/auth/Register";
import ResetPassword from "@/pages/auth/ResetPassword";
import SignIn from "@/pages/auth/SignIn";
import Verify from "@/pages/auth/Verify";
import VerifyRequest from "@/pages/auth/VerifyRequest";
import Index from "@/pages/index/Index";

import "@/assets/bootstrap.scss";
import "@/style.scss";

export const ProtectedRoute = ({ children }) => {
	const isAuthenticated = AuthService.isAuthenticated;
	if (!isAuthenticated) {
		// user is not authenticated
		return <Navigate to="/login" />;
	}
	return children;
};

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		children: [
			{
				index: true,
				element: (
					<ProtectedRoute>
						<Index />
					</ProtectedRoute>
				),
			},
			{ path: "/login", element: <SignIn /> },
			{ path: "/register", element: <Register /> },
			{ path: "/verify", element: <Verify /> },
			{ path: "/verify-request", element: <VerifyRequest /> },
			{ path: "/forgot", element: <ForgotPassword /> },
			{ path: "/reset", element: <ResetPassword /> },
		],
	},
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
