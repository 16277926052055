import { useCallback, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { DataStateCard } from "@/pages/index/DataState";
import { Forecasts } from "@/pages/index/Forecasts";
import { Log } from "@/pages/index/Log";
import { ModelStateCard } from "@/pages/index/ModelState";
import { PipelineStateCard } from "@/pages/index/PipelineState";

export default function Index() {
	const [showLog, setLogShow] = useState(false);

	const handleLogToggle = useCallback(() => {
		setLogShow((prevShow) => !prevShow);
		if (!showLog) {
			setTimeout(() => {
				document.documentElement.scroll({
					top: 99999, // offsetPosition,
					behavior: "smooth",
				});
			}, 500);
		}
	}, [showLog]);

	return (
		<main>
			<div className="container container-my">
				<Tabs defaultActiveKey="turkish" className="mt-3">
					<Tab eventKey="turkish" title="Turkish steel">
						<Forecasts target="MB-STE-0096-Mid" />
					</Tab>
					<Tab eventKey="bdsv" title="BDSV">
						<Forecasts target="BDSV-E2-8" />
					</Tab>
				</Tabs>

				<div className="row">
					<div className="col-12 col-lg-4">
						<PipelineStateCard showLog={showLog} onLogToggle={handleLogToggle} />
					</div>
					<div className="col-12  col-lg-4">
						<DataStateCard />
					</div>
					<div className="col-12  col-lg-4">
						<ModelStateCard />
					</div>
				</div>
				<div className="row log-block">
					<div className="col">{showLog && <Log />}</div>
				</div>
			</div>
		</main>
	);
}
