import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";

import { getCurrentModel } from "@/components/forecast.service";
import Loader from "@/components/Loader";
import { Card } from "@/pages/index/Card";
import { ForecastCurrentModel } from "@/types";

const formatDate = (date) => {
	return dayjs(date).format("DD.MM.YYYY");
};
export const ModelStateCard = () => {
	const [loading, setLoading] = useState(false);

	const [forecastModels, setForecastModels] = useState<ForecastCurrentModel[]>();

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const models = await getCurrentModel();
			if (models) {
				setForecastModels(models);
				setLoading(false);
			}
		};
		fetchData();
	}, []);
	return (
		<Card header={"Model State"}>
			{loading ? (
				<Loader />
			) : (
				<table className="date-table">
					<tbody>
						{forecastModels?.map((forecastModel, j) => (
							<Fragment key={j}>
								<tr>
									<th>
										{forecastModel?.target} {forecastModel?.ensemble_models ? "ensemble" : "model"}
									</th>
									<td>{formatDate(forecastModel?.version)}</td>
								</tr>
								{forecastModel?.ensemble_models?.map((item, i) => (
									<Fragment key={i}>
										<tr>
											<th>|_ model_{i + 1}</th>
											<td>{formatDate(item)}</td>
										</tr>
									</Fragment>
								))}
							</Fragment>
						))}
					</tbody>
				</table>
			)}
			<br />
			<p>This section show what version of the models are deployed and will be used for forecast</p>
		</Card>
	);
};
