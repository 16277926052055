import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { NavLink } from "react-router-dom";
import * as yup from "yup";

import AuthServise from "@/components/auth/service";

const schemaRegisterForm = yup.object({
	email: yup.string().email("must be a valid email").required("Email is required"),
	password: yup
		.string()
		.min(3, "must be at least 3 characters long")
		.required("Password is required"),
	password2: yup
		.string()
		.required("Confirm Password is required")
		.min(3, "must be at least 3 characters long")
		.oneOf([yup.ref("password")], "Passwords do not match"),
});
interface Register {
	email: string;
	password: string;
	password2: string;
}
export default function Register() {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<Register>({
		mode: "onTouched",
		resolver: yupResolver(schemaRegisterForm),
	});
	const [error, setErrors] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const onSubmit = async (form_data) => {
		setIsLoading(true);
		setErrors("");

		await AuthServise.register(form_data)
			.then((res) => {
				setIsLoading(false);
				setSuccess(true);
				setErrors("");
			})
			.catch((err) => {
				//console.log("err", err);
				setIsLoading(false);

				if (err.response) {
					if (err.response.status === 500) {
						setErrors("Error: server error");
						return;
					}
					if (err.response.status === 400) {
						if (err.response.data?.detail === "REGISTER_USER_ALREADY_EXISTS") {
							setErrors("Error: user with this email already exists.");
							return;
						}
						if (err.response.data?.detail?.code === "REGISTER_INVALID_PASSWORD") {
							setErrors(`Error:  ${err.response.data.detail.reason}`);
							return;
						}
						if (err.response.data?.detail?.code === "REGISTER_INVALID_EMAIL_DOMAIN") {
							setErrors(`Error:  ${err.response.data.detail.reason}`);
							return;
						}
					}
					setErrors("Something went wrong");
				} else if (err.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(err.request);
					setErrors("Error: no response");
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log("Error", err.message);
					setErrors("Error: wrong request");
				}
				console.log(err.config);
			});
		//return false;
	};

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	return (
		<>
			<div className={"container sign-in-form"}>
				<div className="card" style={{ width: "18rem", margin: "10px auto" }}>
					<div className="card-body">
						<h5 className="card-title">Registration</h5>
						{success ? (
							<div className="alert alert-success">
								You are successfully registered. Please before login check your email and confirm
								your account.
							</div>
						) : (
							<>
								<form onSubmit={handleSubmit(onSubmit)}>
									<div className="form-group row px-3">
										<label>Email</label>
										<input {...register("email")} className={"form-control  mb-2"} />
										{errors?.email?.message && (
											<div className="alert alert-danger">{errors?.email?.message}</div>
										)}
									</div>

									<div className="form-group row px-3">
										<label>Password</label>
										<div className="input-group p-0 mb-2">
											<input
												type={showPassword ? "text" : "password"}
												{...register("password", {
													required: "Password is required",
												})}
												className={"form-control"}
											/>
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={toggleShowPassword}
											>
												{showPassword ? <BsEye></BsEye> : <BsEyeSlash></BsEyeSlash>}
											</button>
										</div>
										{errors?.password?.message && (
											<div className="alert alert-danger">{errors?.password?.message}</div>
										)}
									</div>

									<div className="form-group  row px-3 mb-2">
										<label>Confirm Password</label>
										<div className="input-group p-0 mb-2">
											<input
												type={showPassword ? "text" : "password"}
												{...register("password2")}
												className={"form-control"}
											/>
											<button
												className="btn btn-outline-secondary"
												type="button"
												onClick={toggleShowPassword}
											>
												{showPassword ? <BsEye></BsEye> : <BsEyeSlash></BsEyeSlash>}
											</button>
										</div>
										{errors?.password2?.message && (
											<div className="alert alert-danger alert-error">
												{errors?.password2?.message}
											</div>
										)}
									</div>

									{error ? <div className="alert alert-danger">{error}</div> : ""}

									<div className="d-flex justify-content-end">
										{isLoading ? (
											<div className="spinner-grow text-primary" role="status">
												<span className="visually-hidden">Loading...</span>
											</div>
										) : (
											""
										)}

										<button type="submit" className="btn btn-primary">
											Register
										</button>
									</div>
								</form>
								<p className="mt-3">
									Do you already registered and want to{" "}
									<NavLink to="/verify-request">request</NavLink> a new verification link?
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
