import { createAxiosClient } from "@/components/auth/createAxiosClient";
import AuthServise from "@/components/auth/service";

const BASE_URL = "/api/v1/";

export function getCurrentAccessToken() {
	return localStorage.getItem("access");
}

function setAccessToken(token) {
	console.log("set tokens");
	localStorage.setItem("access", token);
}

async function logout() {
	console.log("logout");
	AuthServise.logout();
}

export const client = createAxiosClient({
	options: {
		baseURL: BASE_URL,
		timeout: 300000,
		headers: {
			"Content-Type": "application/json",
		},
	},
	getCurrentAccessToken,
	logout,
	setAccessToken,
});
