import dayjs from "dayjs";
import { Fragment } from "react";

import { getPeriod } from "@/lib/utils";
import { ForecastData } from "@/types";
import "@/pages/index/TrendHistory.scss";

export const TrendHistory = ({ forecast }: { forecast: ForecastData }) => {
	const formatDate = (date) => {
		return dayjs(date).format("DD.MM.YYYY");
	};

	return (
		<div className="forecast-detail">
			<div className="row">
				<div className="col-12 col-lg-12">
					<table className="trend-table">
						<tbody>
							<tr>
								<th>Trend date</th>
								{forecast?.trend_info?.map((item, i) => (
									<Fragment key={i}>
										<th>
											Actual Trend {item.shift} {getPeriod(forecast.forecast.granularity)}
										</th>
										<th>
											Forecasted Trend {item.shift} {getPeriod(forecast.forecast.granularity)}
										</th>
									</Fragment>
								))}
							</tr>
							{forecast?.trend_dates?.map((date) => (
								<tr key={date}>
									<td>{formatDate(date)}</td>
									{forecast?.trend_info?.map((item, i) => (
										<Fragment key={i}>
											<td className={item.history[date]?.status}>
												{item.history[date]?.actual_trend || "-"}
											</td>
											<td className={item.history[date]?.status}>
												{item.history[date]?.forecasted_trend || "-"}
											</td>
										</Fragment>
									))}
								</tr>
							))}
						</tbody>
					</table>
				</div>
				<div>
					<div className="notes">
						<p>
							<span className="good">green rows</span> - actual and forecasted trends match
							<br />
							<span className="warning">yellow rows</span> - actual and forecasted trends mismatch,
							but with nearby values (UP and NO, DOWN and NO)
							<br />
							<span className="bad">red rows</span> - actual and forecasted trends mismatch (UP and
							DOWN)
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};
