import { useCallback, useEffect, useRef, useState } from "react";

import { getWebSocketStates, sendPipeline } from "@/components/forecast.service";
import Loader from "@/components/Loader";
import { EventBus } from "@/lib/event-bus";
import ReconnectSocket from "@/lib/ReconnectSocket";
import { Card } from "@/pages/index/Card";
import "@/pages/index/PipelineState.scss";

interface DataJobState {
	fast_market: string;
	metal_source: string;
	steel_orbis: string;
	bdsv: string;
	data_ingest: string;
	forecast: string;
	train: string;
}

interface DataJob {
	job: string;
	status: string;
	datetime: string;
	id: number;
}

export const PipelineStateCard = ({ showLog, onLogToggle }) => {
	const [loading, setLoading] = useState(true);

	const [dataJobState, setDataJobState] = useState<DataJobState>({
		fast_market: "init",
		metal_source: "init",
		steel_orbis: "init",
		bdsv: "init",
		data_ingest: "init",
		forecast: "init",
		train: "init",
	});

	const INGEST_JOBS = ["fast_market", "metal_source", "steel_orbis", "bdsv"];

	const classDot = {
		completed: "bg-success",
		failed: "bg-danger",
		running: "spinner",
		unknown: "bg-secondary",
		init: "bg-secondary",
	};

	const [webSocket, setWebSocket] = useState<ReconnectSocket>();

	const webSocketRef = useRef<ReconnectSocket>();

	useEffect(() => {
		webSocketRef.current = webSocket;
	}, [webSocket]);

	const closeConnection = useCallback(() => {
		if (webSocketRef.current) {
			webSocketRef.current.disconnect();
		}
	}, []);

	const updateDataJobStates = useCallback(
		(event: MessageEvent) => {
			console.log("Get Job States");
			// Logic to handle status updates
			const data_list: DataJob[] = JSON.parse(event.data).items;
			const data = {
				fast_market: "init",
				metal_source: "init",
				steel_orbis: "init",
				bdsv: "init",
				data_ingest: "init",
				forecast: "init",
				train: "init",
			};

			for (const item of data_list) {
				data[item.job] = item.status;
			}

			for (const jobState in dataJobState) {
				if (
					dataJobState[jobState] !== "completed" &&
					dataJobState[jobState] !== "init" &&
					data[jobState] === "completed"
				) {
					switch (jobState) {
						case "data_ingest":
							console.log("emit", jobState);
							EventBus.$emit("update-data-source");
							break;
						case "forecast":
							EventBus.$emit("update-forecast");
							break;
						case "train":
							EventBus.$emit("update-train");
							break;
					}
				}
			}
			setDataJobState(data);
			setLoading(false);
		},
		[dataJobState]
	);
	useEffect(() => {
		console.log("updateDataJobStates");
		if (webSocketRef.current) {
			webSocketRef.current.setOnMessage(updateDataJobStates);
		}
	}, [updateDataJobStates]);

	useEffect(() => {
		setWebSocket(getWebSocketStates(updateDataJobStates));

		return closeConnection;
	}, []);

	const startPipeline = (pipelineId: string) => {
		if (pipelineId == "data_ingest") {
			setDataJobState((data) => {
				return {
					...data,
					data_ingest: "running",
					fast_market: "running",
					steel_orbis: "running",
					metal_source: "running",
					bdsv: "running",
				};
			});
		} else if (INGEST_JOBS.includes(pipelineId)) {
			setDataJobState((data) => {
				return { ...data, [pipelineId]: "running", data_ingest: "running" };
			});
		} else {
			setDataJobState((data) => {
				return { ...data, [pipelineId]: "running" };
			});
		}

		sendPipeline(pipelineId).then((kube) => {
			console.log("activate pipelines", kube);
		});
	};

	const btnDisabled = () => {
		return Object.values(dataJobState).some((value) => value === "running");
	};

	return (
		<Card header={"Pipeline State"}>
			{loading ? (
				<Loader />
			) : (
				<div className="container">
					<div className="row">
						<div className="col-auto col-sm-6 col-md-5 col-lg-5 col-xl-4">
							<table className="date-table table-1">
								<tbody>
									<tr>
										<th>
											<button
												className="btn btn-primary btn-small"
												onClick={() => startPipeline("data_ingest")}
												disabled={btnDisabled()}
											>
												Data
											</button>
										</th>
										<td>
											<div
												className={`dot rounded-circle ${classDot[dataJobState.data_ingest]}`}
											></div>
										</td>
									</tr>
									<tr>
										<th>
											-{" "}
											<button
												className="btn btn-primary btn-small"
												onClick={() => startPipeline("fast_market")}
												disabled={btnDisabled()}
											>
												FM
											</button>
										</th>
										<td>
											<div
												className={`dot rounded-circle ${classDot[dataJobState.fast_market]}`}
											></div>
										</td>
									</tr>
									<tr>
										<th>
											-{" "}
											<button
												className="btn btn-primary btn-small"
												onClick={() => startPipeline("metal_source")}
												disabled={btnDisabled()}
											>
												MS
											</button>
										</th>
										<td>
											<div
												className={`dot rounded-circle ${classDot[dataJobState.metal_source]}`}
											></div>
										</td>
									</tr>
									<tr>
										<th>
											-{" "}
											<button
												className="btn btn-primary btn-small"
												onClick={() => startPipeline("steel_orbis")}
												disabled={btnDisabled()}
											>
												SO
											</button>
										</th>
										<td>
											<div
												className={`dot rounded-circle ${classDot[dataJobState.steel_orbis]}`}
											></div>
										</td>
									</tr>
									<tr>
										<th>
											-{" "}
											<button
												className="btn btn-primary btn-small"
												onClick={() => startPipeline("bdsv")}
												disabled={btnDisabled()}
											>
												BD
											</button>
										</th>
										<td>
											<div className={`dot rounded-circle ${classDot[dataJobState.bdsv]}`}></div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="col-auto col-sm-6  col-md-7 col-lg-7 col-xl-8">
							<div className="row">
								<div className="col-auto">
									<table className="date-table">
										<tbody>
											<tr>
												<th>
													<button
														className="btn btn-primary btn-small"
														onClick={() => startPipeline("forecast")}
														disabled={btnDisabled()}
													>
														Forecast
													</button>
												</th>
												<td>
													<div
														className={`dot rounded-circle ${classDot[dataJobState.forecast]}`}
													></div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="col-auto">
									<table className="date-table">
										<tbody>
											<tr>
												<th>
													<button
														className="btn btn-primary btn-small"
														onClick={() => startPipeline("train")}
														disabled={btnDisabled()}
													>
														Train
													</button>
												</th>
												<td>
													<div
														className={`dot rounded-circle ${classDot[dataJobState.train]}`}
													></div>
												</td>
											</tr>
										</tbody>
									</table>

									<button className="btn btn-secondary btn-sm btn-logs" onClick={onLogToggle}>
										{showLog ? "Hide Logs" : "Show Logs"}
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
			<br />

			<p>
				This section shows information about last Pipeline executions. Grey state is used if
				Pipeline were ran long time ago, like &gt; 1 week or so. With a button we may activate
				pipelines.
			</p>
		</Card>
	);
};
