import dayjs from "dayjs";
import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Toast, ToastContainer } from "react-bootstrap";
import { BsBell } from "react-icons/bs";

import {
	getWebSocketNotifications,
	readNotification,
	readNotifications,
} from "@/components/forecast.service";
import "@/components/Notify.scss";
import ReconnectSocket from "@/lib/ReconnectSocket";

const formatDate = (date) => {
	return dayjs(date).format("DD.MM.YYYY HH:mm:ss");
};
interface Notification {
	id: number;
	datetime: string;
	level: string;
	message: string;
	is_read: boolean;
	is_show?: boolean;
}

const classNotify = {
	info: "secondary",
	success: "success",
	error: "danger",
};
const Notify = () => {
	const [notifications, setNotifications] = useState<Notification[]>([]);
	const [toasts, setToasts] = useState<Notification[]>([]);
	const [start, setStart] = useState<boolean>(true);
	const [newNotifications, setNewNotifications] = useState<boolean>(false);
	const [webSocket, setWebSocket] = useState<ReconnectSocket>();

	const webSocketRef = useRef<ReconnectSocket>();

	useEffect(() => {
		webSocketRef.current = webSocket;
	}, [webSocket]);

	const closeConnection = useCallback(() => {
		if (webSocketRef.current) {
			webSocketRef.current.disconnect();
		}
	}, []);

	const updateNotifications = useCallback(
		(event: MessageEvent) => {
			console.log("Get New Notifications");
			const data = JSON.parse(event.data);
			const oldIds = notifications.map((item) => item.id);

			data.items.find((item) => !item.is_read)
				? setNewNotifications(true)
				: setNewNotifications(false);

			if (!start) {
				const newIdsNotInOldIds = data.items.filter(
					(item) => !item.is_read && !oldIds.includes(item.id)
				);
				setToasts(
					newIdsNotInOldIds.map((item) => {
						item.is_show = true;
						return item;
					})
				);
			}
			setStart(false);
			setNotifications(data.items);
		},
		[notifications, start]
	);

	useEffect(() => {
		console.log("updateNotifications");
		if (webSocketRef.current) {
			webSocketRef.current.setOnMessage(updateNotifications);
		}
	}, [updateNotifications]);

	useEffect(() => {
		setWebSocket(getWebSocketNotifications(updateNotifications));

		return closeConnection;
	}, []);

	const readAll = async (): Promise<void> => {
		if (notifications.find((item) => !item.is_read)) {
			await readNotifications();
			setNewNotifications(false);

			const notificationsRead = notifications.map((item) => {
				item.is_read = true;
				return item;
			});
			setNotifications(notificationsRead);
			setToasts((toasts) =>
				toasts.map((item) => {
					item.is_show = false;
					return item;
				})
			);
			console.log("readAll");
		}
	};

	const updateShow = async (
		id: number,
		event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element> | undefined
	): Promise<void> => {
		if (event) {
			const notifIdx = notifications.findIndex((item) => item.id === id);
			await readNotification(id);
			notifications[notifIdx].is_read = true;
			notifications[notifIdx].is_show = false;

			const notifNotRead = notifications.filter((item) => !item.is_read);
			if (!notifNotRead.length) {
				setNewNotifications(false);
			}
		}
		setToasts((toasts) =>
			toasts.map((item) => {
				if (item.id === id) item.is_show = false;
				return item;
			})
		);
		setNotifications(notifications);
	};

	return (
		<>
			<Dropdown style={{ display: "inline-block", marginRight: "30px" }} onClick={readAll}>
				<Dropdown.Toggle id="dropdown-basic" variant={"dark"}>
					<BsBell color="white" />
					{newNotifications && (
						<span className="position-absolute top-1 start-90 translate-middle p-1 bg-danger border border-light rounded-circle">
							<span className="visually-hidden">New notifications</span>
						</span>
					)}
				</Dropdown.Toggle>

				<Dropdown.Menu className="notif-menu">
					{notifications?.map((item, i) => (
						<Fragment key={item.id}>
							<Dropdown.Divider className={"notif-divider"} />
							<Dropdown.Item className={`notif`}>
								<Toast bg={classNotify[item.level]} key={item.id}>
									<Toast.Header closeButton={false}>
										<strong className="me-auto">Pipeline</strong>
										<small className="text-muted">{formatDate(item.datetime)}</small>
									</Toast.Header>
									<Toast.Body className={"text-white"}>{item.message}</Toast.Body>
								</Toast>
							</Dropdown.Item>
						</Fragment>
					))}
				</Dropdown.Menu>
			</Dropdown>
			<ToastContainer
				position={"bottom-end"}
				style={{ zIndex: 1 }}
				className="position-fixed toast-block"
			>
				{toasts?.map((item, i) => (
					<Toast
						bg={classNotify[item.level]}
						key={item.id}
						onClose={(event) => updateShow(item.id, event)}
						show={item.is_show}
						animation={true}
						autohide={true}
						delay={5000}
					>
						<Toast.Header>
							<strong className="me-auto">Pipeline</strong>
							<small className="text-muted">{formatDate(item.datetime)}</small>
						</Toast.Header>
						<Toast.Body className={"text-white"}>{item.message}</Toast.Body>
					</Toast>
				))}
			</ToastContainer>
		</>
	);
};
export default Notify;
