import { client } from "@/components/auth/axiosClient";
import ReconnectSocket from "@/lib/ReconnectSocket";
import { DataSource, Forecast, ForecastCurrentModel, ForecastData } from "@/types";

const WS_URL =
	import.meta.env.VITE_WS_URL ||
	(window.location.protocol === "https:" ? "wss://" : "ws://") + window.location.hostname;

export const getList = async (target?: string) => {
	const params = target ? { target } : {};
	return client.get<Forecast[]>(`/forecasts/`, { params }).then((response) => response.data);
};

export const getForecast = async (forecastId: number) => {
	return client.get<ForecastData>(`/forecasts/${forecastId}`).then((response) => response.data);
};

export const getDataSources = async () => {
	return client.get<DataSource[]>(`/data/`).then((response) => response.data);
};

export const getCurrentModel = async (target?: string) => {
	const params = target ? { target } : {};
	return client
		.get<ForecastCurrentModel[]>(`/data/model`, { params })
		.then((response) => response.data);
};

export const getWebSocketLogs = (onmessage: (event: MessageEvent) => void): ReconnectSocket => {
	const url = `${WS_URL}/api/v1/pipeline/ws-logs`;
	const socket = new ReconnectSocket(url, "logs", onmessage);
	socket.connect();
	return socket;
};

export const getWebSocketNotifications = (
	onmessage: (event: MessageEvent) => void
): ReconnectSocket => {
	const url = `${WS_URL}/api/v1/pipeline/ws-notifications`;
	const socket = new ReconnectSocket(url, "notifications", onmessage);
	socket.connect();
	return socket;
};
export const getWebSocketStates = (onmessage: (event: MessageEvent) => void): ReconnectSocket => {
	const url = `${WS_URL}/api/v1/pipeline/ws-states`;
	const socket = new ReconnectSocket(url, "states", onmessage);
	socket.connect();
	return socket;
};

export const readNotifications = () => {
	return client.post(`/pipeline/notifications/read`).then((response) => response.data);
};

export const readNotification = (notificationId: number) => {
	return client
		.post(`/pipeline/notifications/read/${notificationId}`)
		.then((response) => response.data);
};

export const sendPipeline = (pipelineId: string) => {
	return client.post(`/pipeline/start/${pipelineId}`).then((response) => response.data);
};

export const exportPDF = async (forecastId: number) => {
	return client
		.get<Blob>(`/forecasts/export/${forecastId}`, {
			responseType: "blob",
		})
		.then((response) => response.data);
};
