class Timer {
	callback: () => void;
	timerCalc: (tries: number) => number;
	timer: number | undefined;
	tries: number;
	constructor(callback: () => void, timerCalc: (tries: number) => number) {
		this.callback = callback;
		this.timerCalc = timerCalc;
		this.timer = undefined;
		this.tries = 0;
	}

	reset(): void {
		this.tries = 0;
		clearTimeout(this.timer);
	}

	scheduleTimeout(): void {
		clearTimeout(this.timer);
		this.timer = setTimeout(() => {
			this.tries = this.tries + 1;
			this.callback();
		}, this.timerCalc(this.tries + 1));
	}
}

export default class ReconnectSocket {
	private webSocket: WebSocket | null;
	private readonly socketUrl: string;
	private reconnectTimer: Timer;
	public onmessage: (event: MessageEvent) => void;
	public readonly name: string;

	constructor(url: string, name: string, onmessage: (event: MessageEvent) => void) {
		this.webSocket = null;
		this.socketUrl = url;
		this.onmessage = onmessage;
		this.name = name;
		this.reconnectTimer = new Timer(() => {
			this.disconnect();
			this.connect();
		}, this.reconnectAfterMs);
	}

	reconnectAfterMs(tries: number): number {
		return [1000, 2000, 5000, 10000][tries - 1] || 10000;
	}

	connect(): void {
		this.webSocket = new WebSocket(this.socketUrl);

		this.webSocket.onopen = (event) => {
			console.log(`WebSocket ${this.name} connection opened`);
			this.webSocket?.send("Socket Test Message");
		};

		this.webSocket.onclose = (event) => {
			console.log(`WebSocket ${this.name} connection closed`);
			this.reconnectTimer.scheduleTimeout();
		};

		this.webSocket.onerror = (event) => {
			console.log(`WebSocket ${this.name} connection error`);
			this.reconnectTimer.reset();
		};

		this.webSocket.onmessage = this.onmessage;
	}

	setOnMessage(onmessage: (event: MessageEvent) => void): void {
		this.onmessage = onmessage;
		if (this.webSocket) {
			this.webSocket.onmessage = this.onmessage;
		}
	}

	disconnect(): void {
		if (this.webSocket) {
			console.log(`WebSocket ${this.name} disconnected`);
			this.webSocket.onclose = () => {};
			this.webSocket.onmessage = () => {};
			this.webSocket.close();
		}
	}
}
