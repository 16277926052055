import { useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";

import AuthServise from "@/components/auth/service";
import Loader from "@/components/Loader";

enum VerifyState {
	INIT,
	SUCCESS,
	ALREADY_VERIFIED,
	ERROR,
}
export default function Verify() {
	const [error, setErrors] = useState("");
	const [verify, setVerify] = useState<VerifyState>(VerifyState.INIT);
	const [searchParams, setSearchParams] = useSearchParams();

	const verificate = (token) => {
		AuthServise.verify(searchParams.get("token"))
			.then((res) => {
				setVerify(VerifyState.SUCCESS);
			})
			.catch((err) => {
				console.log("err", err);

				if (err.response.status === 422) {
					setErrors("Error: validation error");
					setVerify(VerifyState.ERROR);
					return;
				}
				if (err.response.status === 500) {
					setErrors("Error: server error");
					setVerify(VerifyState.ERROR);
					return;
				}
				if (err.response.status === 400) {
					if (err.response.data.detail === "VERIFY_USER_BAD_TOKEN") {
						setErrors(
							"Error: wrong token. Please check your email and click on the link in the email."
						);
						setVerify(VerifyState.ERROR);
						return;
					}
					if (err.response.data.detail === "VERIFY_USER_ALREADY_VERIFIED") {
						setErrors("Error: You are already verified. Please login.");
						setVerify(VerifyState.ALREADY_VERIFIED);
						return;
					}
				}
				setErrors("Something went wrong");
			});
	};

	useEffect(() => {
		if (searchParams.get("token")) {
			verificate(searchParams.get("token"));
		} else {
			setErrors("Error: wrong token. Please check your email and click on the link in the email.");
			setVerify(VerifyState.ERROR);
		}
	}, []);

	return (
		<>
			<div className={"container sign-in-form"}>
				<div className="card" style={{ width: "18rem", margin: "10px auto" }}>
					<div className="card-body">
						<h5 className="card-title">Verification</h5>
						{verify === VerifyState.INIT && <Loader />}
						{verify === VerifyState.ALREADY_VERIFIED && (
							<p>
								You are already verified. Please <NavLink to="/login">login</NavLink>
							</p>
						)}
						{verify === VerifyState.SUCCESS && (
							<p>
								Verification successful. You can now <NavLink to="/login">login</NavLink>
							</p>
						)}
						{verify === VerifyState.ERROR && (
							<>
								<div className="alert alert-danger">{error}</div>
								<p>
									You can <NavLink to="/verify-request">request</NavLink> a new verification link.
								</p>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
