import { client } from "@/components/auth/axiosClient";
import { EventBus } from "@/lib/event-bus";
//https://stackoverflow.com/questions/68572355/how-to-add-a-custom-property-to-axios-config
declare module "axios" {
	export interface AxiosRequestConfig {
		authorization?: boolean;
	}
}

class AuthService {
	login({ username, password }) {
		const formData = new FormData();
		formData.set("username", username.trim());
		formData.set("password", password.trim());

		return client
			.post("/auth/login", formData, {
				authorization: false,
				headers: {
					"Content-Type": "multipart/form-data",
				},
			})
			.then((response) => {
				// console.log();(response.data);
				localStorage.setItem("access", "true");
				return response;
			});
	}

	logout() {
		localStorage.removeItem("access");
		EventBus.$emit("logout");
		window.location.href = "/login";
	}

	get isAuthenticated() {
		return !!localStorage.getItem("access");
	}

	register({ email, password }) {
		const formData = new FormData();
		formData.set("username", email.trim());
		formData.set("password", password.trim());

		return client
			.post(
				"/auth/register",
				{ email, password },
				{
					authorization: false,
				}
			)
			.then((response) => {
				// console.log(response.data);
				return response;
			});
	}

	verify(token) {
		return client
			.post(
				"/auth/verify",
				{ token },
				{
					authorization: false,
				}
			)
			.then((response) => {
				// console.log(response.data);
				return response;
			});
	}

	request_verify(email) {
		return client
			.post(
				"/auth/request-verify-token",
				{ email },
				{
					authorization: false,
				}
			)
			.then((response) => {
				// console.log(response.data);
				return response;
			});
	}

	forgot_password(email) {
		return client
			.post(
				"/auth/forgot-password",
				{ email },
				{
					authorization: false,
				}
			)
			.then((response) => {
				console.log(response.data);
				return response;
			});
	}

	reset_password(token, password) {
		return client
			.post(
				"/auth/reset-password",
				{ token, password },
				{
					authorization: false,
				}
			)
			.then((response) => {
				console.log(response.data);
				return response;
			});
	}

	change_password(token, password) {
		return client
			.patch(
				"/auth/me",
				{ token, password },
				{
					authorization: false,
				}
			)
			.then((response) => {
				console.log(response.data);
				return response;
			});
	}

}
const authService = new AuthService();
export default authService;
