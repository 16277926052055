import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import AuthServise from "@/components/auth/service";
import Notify from "@/components/Notify";
import { EventBus } from "@/lib/event-bus";
const Navbar = () => {
	const [login, setLogin] = useState<boolean>(AuthServise.isAuthenticated);
	useEffect(() => {
		EventBus.$on("logout", () => setLogin(false));
	}, []);

	const handleLogOut = () => {
		AuthServise.logout();
	};
	return (
		<header>
			<nav className="navbar navbar-expand-lg navbar-light bg-dark">
				<div className="container container-my">
					<span className="navbar-brand text-white">OSR-Report Forecast</span>
					{login ? (
						<div>
							<Notify />
							<button className="btn btn-outline-light" onClick={handleLogOut}>
								{/*<BsPerson />*/}
								Log Out
							</button>
						</div>
					) : (
						<div>
							<NavLink to="/login" className="btn btn-outline-light me-2">
								Login
							</NavLink>
							<NavLink to="/register" className="btn btn-outline-light">
								Register
							</NavLink>
						</div>
					)}
				</div>
			</nav>
		</header>
	);
};
export default Navbar;
