import { useState } from "react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";

import AuthServise from "@/components/auth/service";

interface Login {
	username: string;
	password: string;
}
export default function SignIn() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Login>();
	const [error, setErrors] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const onSubmit = async (form_data) => {
		//event.preventDefault();
		setIsLoading(true);

		await AuthServise.login(form_data)
			.then((res) => {
				navigate(`/`);
				//window.location.href = "/";
				window.location.reload();
			})
			.catch((err) => {
				//console.log("err", err);
				setIsLoading(false);

				if (err.response) {
					if (err.response.status === 401) {
						setErrors("Error: wrong credentials");
						return;
					}
					if (err.response.status === 500) {
						setErrors("Error: server error");
						return;
					}
					if (err.response.status === 400) {
						if (err.response.data.detail === "LOGIN_USER_NOT_VERIFIED") {
							setErrors(
								"Error: please verify your email first. " +
									"Check your email inbox for the verification link or request a new one on the registration page"
							);
							return;
						}
						if (err.response.data.detail === "LOGIN_BAD_CREDENTIALS") {
							setErrors("Error: wrong credentials");
							return;
						}
					}

					// The request was made and the server responded with a status code
					// that falls out of the range of 2xx
					console.log(err.response.data);
					console.log(err.response.status);
					console.log(err.response.headers);
					setErrors("Something went wrong");
				} else if (err.request) {
					// The request was made but no response was received
					// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
					// http.ClientRequest in node.js
					console.log(err.request);
					setErrors("Error: no response");
				} else {
					// Something happened in setting up the request that triggered an Error
					console.log("Error", err.message);
					setErrors("Error: wrong request");
				}
				console.log(err.config);
			});
		//return false;
	};

	return (
		<>
			<div className={"container sign-in-form"}>
				<div className="card" style={{ width: "18rem", margin: "10px auto" }}>
					<div className="card-body">
						<h5 className="card-title">Login</h5>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="form-group  row my-3 p-3">
								<label>Email</label>
								<input {...register("username")} className={"form-control"} />
								{errors?.username?.message && (
									<div className="alert alert-danger">{errors?.username?.message}</div>
								)}
							</div>

							<div className="form-group  row my-3 p-3">
								<label>Password</label>
								<input
									type={"password"}
									{...register("password", {
										required: "Password is required",
									})}
									className={"form-control"}
								/>
								{errors?.username?.message && (
									<div className="alert alert-danger">{errors?.password?.message}</div>
								)}
							</div>

							{error ? <div className="alert alert-danger">{error}</div> : ""}

							<div className="d-flex justify-content-end">
								{isLoading ? (
									<div className="spinner-grow text-primary" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								) : (
									""
								)}
								<div className="row">
									<div className="col">
										<button type="submit" className="btn btn-primary">
											Sign In
										</button>
									</div>
								</div>
							</div>
						</form>

						<div className="row">
							<div className="col left">
								<NavLink to="/forgot" className="btn btn-outline">
									forgot password?
								</NavLink>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
