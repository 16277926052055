import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { NavLink, useSearchParams } from "react-router-dom";
import * as yup from "yup";

import AuthServise from "@/components/auth/service";
import Loader from "@/components/Loader";

const schemaResetPassword = yup.object({
	password: yup
		.string()
		.min(3, "must be at least 3 characters long")
		.required("Password is required"),
	password2: yup
		.string()
		.required("Confirm Password is required")
		.min(3, "must be at least 3 characters long")
		.oneOf([yup.ref("password")], "Passwords do not match"),
});
interface ResetPassword {
	password: string;
	password2: string;
}

enum ResetState {
	INIT,
	TOKEN,
	SUCCESS,
	FAIL,
	ERROR,
}
export default function ResetPassword() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPassword>({
		mode: "onTouched",
		resolver: yupResolver(schemaResetPassword),
	});
	const [error, setErrors] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [token, setToken] = useState<string | null>(null);
	const [reset, setReset] = useState<ResetState>(ResetState.INIT);
	const [showPassword, setShowPassword] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (searchParams.get("token")) {
			setReset(ResetState.TOKEN);
			setToken(searchParams.get("token"));
		} else {
			setErrors("Error: wrong token. Please check your email and click on the link in the email.");
			setReset(ResetState.ERROR);
		}
	}, [searchParams]);

	const onSubmit = async (form_data) => {
		//event.preventDefault();
		setIsLoading(true);

		await AuthServise.reset_password(token, form_data.password)
			.then((res) => {
				setReset(ResetState.SUCCESS);
			})
			.catch((err) => {
				//console.log("err", err);
				setIsLoading(false);

				if (err.response.status === 422) {
					setErrors("Error: validation error");
					setReset(ResetState.ERROR);
					return;
				}
				if (err.response.status === 500) {
					setErrors("Error: server error");
					setReset(ResetState.ERROR);
					return;
				}
				if (err.response.status === 400) {
					if (err.response.data.detail === "RESET_PASSWORD_BAD_TOKEN") {
						setErrors(
							"Error: wrong token. Please check your email and click on the link in the email or " +
								"request another verification email. "
						);
						setReset(ResetState.FAIL);
						return;
					}
					if (err.response.data?.detail?.code === "RESET_PASSWORD_INVALID_PASSWORD") {
						setErrors(`Error:  ${err.response.data.detail.reason}`);
						setReset(ResetState.FAIL);
						return;
					}
				}
				setErrors("Something went wrong");
			});
		//return false;
	};

	const toggleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	return (
		<>
			<div className={"container sign-in-form"}>
				<div className="card" style={{ width: "18rem", margin: "10px auto" }}>
					<div className="card-body">
						<h5 className="card-title">Reset Password</h5>
						{reset === ResetState.INIT && <Loader className="loader" />}
						{reset === ResetState.SUCCESS && (
							<p>
								Password successfully changed. You can now <NavLink to="/login">login</NavLink>
							</p>
						)}
						{reset === ResetState.ERROR && <div className="alert alert-danger">{error}</div>}
						{reset === ResetState.TOKEN && (
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group row px-3">
									<label>Password</label>
									<div className="input-group p-0 mb-2">
										<input
											type={showPassword ? "text" : "password"}
											{...register("password", {
												required: "Password is required",
											})}
											className={"form-control"}
										/>
										<button
											className="btn btn-outline-secondary"
											type="button"
											onClick={toggleShowPassword}
										>
											{showPassword ? <BsEye></BsEye> : <BsEyeSlash></BsEyeSlash>}
										</button>
									</div>
									{errors?.password?.message && (
										<div className="alert alert-danger">{errors?.password?.message}</div>
									)}
								</div>

								<div className="form-group  row px-3 mb-2">
									<label>Confirm Password</label>
									<div className="input-group p-0 mb-2">
										<input
											type={showPassword ? "text" : "password"}
											{...register("password2")}
											className={"form-control"}
										/>
										<button
											className="btn btn-outline-secondary"
											type="button"
											onClick={toggleShowPassword}
										>
											{showPassword ? <BsEye></BsEye> : <BsEyeSlash></BsEyeSlash>}
										</button>
									</div>
									{errors?.password2?.message && (
										<div className="alert alert-danger alert-error">
											{errors?.password2?.message}
										</div>
									)}
								</div>

								{error ? <div className="alert alert-danger">{error}</div> : ""}

								<div className="d-flex justify-content-end">
									{isLoading ? (
										<div className="spinner-grow text-primary" role="status">
											<span className="visually-hidden">Loading...</span>
										</div>
									) : (
										""
									)}

									<button type="submit" className="btn btn-primary">
										Request
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
