import { useState } from "react";
import { useForm } from "react-hook-form";

import AuthServise from "@/components/auth/service";

interface ForgotPassword {
	email: string;
}

export default function ForgotPassword() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ForgotPassword>();
	const [error, setErrors] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const onSubmit = (form_data) => {
		setIsLoading(true);

		AuthServise.forgot_password(form_data.email)
			.then((res) => {
				setIsLoading(false);
				setSuccess(true);
			})
			.catch((err) => {
				//console.log("err", err);
				setIsLoading(false);
				if (err.response.status === 500) {
					setErrors("Error: server error");
					return;
				}
				setErrors("Error: something went wrong");
			});
	};

	return (
		<>
			<div className={"container sign-in-form"}>
				<div className="card" style={{ width: "18rem", margin: "10px auto" }}>
					<div className="card-body">
						<h5 className="card-title">Request Reset Password</h5>
						{success ? (
							<div className="alert alert-success">
								Please check your email to reset your password.
							</div>
						) : (
							<form onSubmit={handleSubmit(onSubmit)}>
								<div className="form-group  row my-3 p-3">
									<label>Email</label>
									<input {...register("email")} className={"form-control"} />
									{errors?.email?.message && (
										<div className="alert alert-danger">{errors?.email?.message}</div>
									)}
								</div>

								{error ? <div className="alert alert-danger">{error}</div> : ""}

								<div className="d-flex justify-content-end">
									{isLoading ? (
										<div className="spinner-grow text-primary" role="status">
											<span className="visually-hidden">Loading...</span>
										</div>
									) : (
										""
									)}

									<button type="submit" className="btn btn-primary">
										Submit
									</button>
								</div>
							</form>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
