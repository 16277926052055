import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";

import { exportPDF, getForecast, getList } from "@/components/forecast.service";
import Loader from "@/components/Loader";
import { EventBus } from "@/lib/event-bus";
import { ForecastDetail } from "@/pages/index/ForecastDetail";
import { TrendHistory } from "@/pages/index/TrendHistory";
import { Forecast, ForecastData } from "@/types";

import "@/pages/index/Forecasts.scss";

export const Forecasts = ({ target }: { target: string }) => {
	const [loadings, setLoadings] = useState({
		isForecastListLoading: true,
		isForecastDetailLoading: false,
		isExportLoading: false,
	});
	const [showParams, setShowParams] = useState(false);
	const [fetchListError, setFetchListError] = useState("");
	const [fetchDetailError, setFetchDetailError] = useState("");

	const handleParamsToggle = useCallback(() => {
		setShowParams((prevShow) => !prevShow);
	}, []);
	const [forecasts, setForecasts] = useState<Forecast[] | null>(null);
	const [selectedForecast, setSelectedForecast] = useState<Forecast | null>(null);
	const [currentForecast, setCurrentForecast] = useState<ForecastData | null>(null);

	const fetchData = async () => {
		setLoadings({ ...loadings, isForecastListLoading: true });
		let forecastList;
		try {
			forecastList = await getList(target);
			setFetchListError("");
		} catch (error) {
			setFetchListError(
				"Something went wrong, please try again later. If error will appear again contact administrator"
			);
			console.error("forecastList error", error);
			setLoadings({ ...loadings, isForecastListLoading: false });
			return;
		}
		setLoadings({ ...loadings, isForecastListLoading: false });

		if (forecastList) {
			for (const forecast of forecastList) {
				forecast.datetime = dayjs(forecast.datetime).format("YYYY MMM DD, HH:MM");
			}
			if (forecastList.length) {
				setForecasts(forecastList);
				setSelectedForecast(forecastList[0]);
				setLoadings({ ...loadings, isForecastDetailLoading: true, isForecastListLoading: false });
				try {
					const forecastDetail = await getForecast(forecastList[0].id);
					setCurrentForecast(forecastDetail);
					setFetchDetailError("");
				} catch (error) {
					setFetchDetailError(
						"Something went wrong, please try again later. If error will appear again contact administrator"
					);
					console.error("fetchDetailError error", error);
				}
			}
		}
		setLoadings({
			...loadings,
			isForecastDetailLoading: false,
			isForecastListLoading: false,
		});
	};

	useEffect(() => {
		fetchData().then(() => {
			EventBus.$on("update-forecast", fetchData);
		});
	}, []);

	const handleForecastClick = async (forecast: Forecast | undefined) => {
		if (forecast) {
			// console.log(forecast);
			setLoadings({ ...loadings, isForecastDetailLoading: true });
			try {
				const forecastDetail = await getForecast(forecast.id);
				setSelectedForecast(forecast);
				setCurrentForecast(forecastDetail);
				setFetchDetailError("");
			} catch (error) {
				setFetchDetailError(
					"Something went wrong, please try again later. If error will appear again contact administrator"
				);
				console.error("fetchDetailError error", error);
			}
			setLoadings({ ...loadings, isForecastDetailLoading: false });
		}
	};

	function handleExportClick() {
		if (currentForecast) {
			setLoadings({ ...loadings, isExportLoading: true });
			exportPDF(currentForecast.forecast.id).then((blob) => {
				setLoadings({ ...loadings, isExportLoading: false });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.href = url;
				a.download = `report_${currentForecast.date_forecast}_${currentForecast.forecast.target}.pdf`;
				a.target = "_blank";
				document.body.appendChild(a);
				a.click();
				document.body.removeChild(a);
			});
		}
	}

	return (
		<div className="App">
			{loadings.isForecastListLoading ? (
				<Loader className="loader" />
			) : fetchListError ? (
				<div className="alert alert-danger" role="alert">
					{fetchListError}
				</div>
			) : forecasts?.length ? (
				<>
					<div className="row">
						<div className="col-6">
							<div className={"d-flex align-items-start"}>
								<select
									className="form-select forecast-select"
									value={selectedForecast?.id}
									onChange={(event) => {
										const selectedForecastId = Number(event.target.value) || null;
										const findForecast = forecasts?.find(
											(forecast) => forecast.id === selectedForecastId
										);
										handleForecastClick(findForecast);
									}}
								>
									{forecasts?.map((forecast) => (
										<option key={forecast.id} value={forecast.id}>
											{forecast.datetime}
										</option>
									))}
								</select>

								<div className="loader-export-block">
									{loadings.isExportLoading && <Loader className="loader-export" />}
								</div>

								<button className="btn btn-primary btn-export" onClick={handleExportClick}>
									Export
								</button>
							</div>
						</div>
						<div className="col-6 text-end">
							<button className="btn btn-secondary btn-sm" onClick={handleParamsToggle}>
								{showParams ? "Hide Params" : "Show Params"}
							</button>
						</div>
					</div>

					{loadings.isForecastDetailLoading ? (
						<Loader className="loader" />
					) : fetchDetailError ? (
						<div className="alert alert-danger alert-block" role="alert">
							{fetchDetailError}
						</div>
					) : (
						<>
							{currentForecast ? (
								<Tabs defaultActiveKey="forecast" className="mt-3">
									<Tab eventKey="forecast" title="Forecast">
										<ForecastDetail forecast={currentForecast} showParams={showParams} />
									</Tab>
									<Tab eventKey="trends" title="Trend history">
										<TrendHistory forecast={currentForecast} />
									</Tab>
								</Tabs>
							) : (
								<></>
							)}
						</>
					)}
				</>
			) : (
				<p>No forecasts found</p>
			)}
		</div>
	);
};
