import dayjs from "dayjs";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";

import { getWebSocketLogs } from "@/components/forecast.service";
import "@/pages/index/Log.scss";
import Loader from "@/components/Loader";
import ReconnectSocket from "@/lib/ReconnectSocket";
import { Card } from "@/pages/index/Card";

const formatDate = (date) => {
	return dayjs(date).format("DD.MM.YYYY HH:mm:ss");
};

interface Log {
	datetime: string;
	level: string;
	message: string;
}

const classLog = {
	info: "secondary",
	success: "success",
	warning: "warning",
	error: "danger",
};
const Log = () => {
	const [logs, setLogs] = useState<Log[]>([]);
	const [loading, setLoading] = useState(true);
	const [webSocket, setWebSocket] = useState<ReconnectSocket>();

	const webSocketRef = useRef<ReconnectSocket>();

	useEffect(() => {
		webSocketRef.current = webSocket;
	}, [webSocket]);

	const closeConnection = useCallback(() => {
		if (webSocketRef.current) {
			webSocketRef.current.disconnect();
		}
	}, []);

	const updateLogs = useCallback((event: MessageEvent) => {
		// console.log(event.data);
		const data = JSON.parse(event.data);
		setLogs(data.items);
		setLoading(false);
	}, []);

	useEffect(() => {
		setWebSocket(getWebSocketLogs(updateLogs));
		return closeConnection;
	}, [closeConnection, updateLogs]);

	return (
		<Card header={"Logs"}>
			{loading ? (
				<Loader />
			) : logs && logs.length > 0 ? (
				<table className="table logs-table">
					<tbody>
						{logs?.map((item, i) => (
							<Fragment key={i}>
								<tr>
									<td>{formatDate(item.datetime)}</td>
									<td>
										<span className={`badge bg-${classLog[item.level]}`}>{item.level}</span>
									</td>
									<td>{item.message}</td>
								</tr>
							</Fragment>
						))}
					</tbody>
				</table>
			) : (
				<p>No logs yet</p>
			)}
		</Card>
	);
};

export { Log };
