const decodeToken = (token) => {
	const token_parts = token.split(/\./);
	return JSON.parse(window.atob(token_parts[1])).exp;
};

function getPeriod(granularity: string) {
	const gr = {
		"W-MON": "week",
		MS: "month",
	};
	if (gr[granularity]) return gr[granularity];
	return granularity;
}

function getGranularity(granularity: string) {
	return getPeriod(granularity) + "ly";
}
export { decodeToken, getPeriod, getGranularity };
